<template>
    <auth-page-template heading="Verify  your Mobile Number">
        <template #subSection>
            <p class="mb-41">An OTP has been sent to your registered mobile number +91 8569 562 326</p>
            <p class="mb-3">Please enter the OTP below to verify your number.</p>
        </template>
            <div>
                <validated-input type="password" label="Enter OTP"></validated-input>
            </div>
            <div class="text-center">
                <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4" text="Login"></btn>
                <p class="mt-2">Not yet received ?
                    <router-link to="/">Resend</router-link>
                </p>
            </div>
    </auth-page-template>
</template>

<script>
import AuthPageTemplate from '@components/auth/AuthPageTemplate';

export default {
    name: 'VerifyMobilePage',
    components: { AuthPageTemplate }
};
</script>

<style scoped>

</style>
